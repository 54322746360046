<template lang="html">
  <footer class="footer footer--blue color--white small">
    <div class="container">
      <div class="row">
        <div class="col-md-4 order-2 order-md-1 d-flex flex-column justify-content-center">
          <p class="mb-0">© 2019 SCHL - All Rights Reserved web & design by <a href="http://wgrcommunication.ch" target="_blank">WGR SA</a></p>
        </div>
        <div class="col-md-8 order-1 order-md-2 text-left text-md-right">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <router-link to="/news" >Actualités</router-link>
            </li>
            <li class="list-inline-item">
              <router-link to="/objets-a-louer">
                Objets à louer
              </router-link>
            </li>
            <li class="list-inline-item">
              <router-link to="/pages/protection-des-donnees">
                nLPD
              </router-link>
            </li>
            <li class="list-inline-item">
              <router-link to="/pages/coordonnees-bancaires">
                Coordonnées bancaires
              </router-link>
            </li>
            <li class="list-inline-item">
              <router-link to="/pages/liens">
                Liens
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data: function(){
    return {
    }
  },
  methods: {
  }
}
</script>
