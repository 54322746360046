export default
{
  namespaced: true,
  state:
  {
    flashes:{
      default:[]
    },
    flash:
    {
      type: 'primary', // OR secondary, success, danger, warning
      message: 'hello world'
    },
    changes: 0
  },
  actions:
  {
    add ({commit}, {channel, flash})
    {
      commit('add',{flash, channel})
    },
    remove ({commit}, {channel, ref})
    {
      console.log(channel, ref);
      commit('remove',{ref, channel})
    },
  },
  mutations:
  {
    add(state, { flash, channel })
    {
      channel = channel? channel: 'default'
      if(!state.flashes[channel]) state.flashes[channel] = []
      state.flashes[channel].push(Object.assign({}, state.flash, flash))
      state.changes++
    },
    remove(state, { ref, channel })
    {
      channel = channel? channel: 'default'
      if(!state.flashes[channel]) state.flashes[channel] = []
      state.flashes[channel].splice(ref, 1)
      state.changes--
    }
  }
}
