import createCrudModule from 'vuex-crud';
import {client, parseResponse} from '@/api'

export default createCrudModule({
  resource: 'carousels',
  idAttribute: 'slug',
  client,
  parseSingle: parseResponse,
  parseList: parseResponse,
  customUrlFn(slug) {
    return process.env.VUE_APP_API + `api/carousels/view/${slug}`
  }
});
