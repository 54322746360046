<template lang="html">
  <header class="header" :class="{scrolled: pageScrolled}" v-scroll="isScrolled">

    <div class="header__spacer"></div>
    <div class="header__content">
      <!--<section v-if="$store.state.route.path == '/'" class="section section--highlight section--yellow color--blue text-center">
        <div class="container">
          <h4 class="mb-0">En raison de la situation sanitaire liée au Covid-19, nos bureaux seront fermés jusqu'à nouvel avis. Vous pouvez nous atteindre par téléphone ou nous adresser un courriel voire un courrier. Nous vous remercions de votre compréhension.</h4>
        </div>
      </section>-->
      <div class="position-relative">
        <div class="utils--spacer-default"></div>
        <div class="container">
          <div class="block block--menu">
            <div class="block__btns">
              <div class="block__btn btn btn--small btn--blue-light color--blue">
                <router-link to="/news">Actualités</router-link>
              </div>
              <div class="block__btn btn btn--small btn--blue color--white">
                <router-link to="/objets-a-louer">Objets à louer</router-link>
              </div>
              <!-- <div class="block__btn btn btn--small btn--blue color--white">
                <li class="dropdown" :class="{active: dropDownShown100}" @mouseover="dropDownShown100 = true"
                  @mouseleave="dropDownShown100 = false">
                  <span class="block__nav-title" :class="{
                      bold: (
                        $store.state.route.path == '/pages/accueil' ||
                        $store.state.route.path == '/pages/cadeau' ||
                        $store.state.route.path == '/pages/programme' ||
                        $store.state.route.path == '/pages/festival' ||
                        $store.state.route.path == '/pages/inscription'
                      )
                    }" title="🎂 100ème">🎂 100ème</span>
                  <ul>
                    <li @click="showMenu()">
                      <router-link to="/pages/accueil">
                        <span class="block__nav-title" title="Accueil">Accueil</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/pages/accueil" v-scroll-to="'#h_54636241610701574689849297'">
                        Le Film 100<sup>e</sup>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/pages/accueil" v-scroll-to="'#h_88098567111601574689861902'">
                        Living Zoom
                      </router-link>
                    </li>

                    <li>
                      <a href="https://www.facebook.com/SCHL100/" target="_blank">
                        <img src="@/assets/img/fb.svg" width="13" height="13">
                        &nbsp;SCHL100
                      </a>
                    </li>
                  </ul>
                </li>
              </div> -->
              <div class="block__btn btn btn--small btn--yellow color--black">
                <a href="https://portail.schl.ch/portail/static/#/login" target="_blank">
                  Espace personnel
                </a>
              </div>

            </div>
            <div class="block__logo">
              <router-link to="/">
                <img src="@/assets/img/logo/SCHL.png" class="img-fluid">
              </router-link>
            </div>
            <ul class="block__list list-unstyled" :class="{active: menuShown}">
              <li class="dropdown" :class="{active: dropDownShownPage,}" @mouseover="activeDropDownPage();"
                @mouseleave="dropDownShownPage = false; dropDownShown100 = false;">
                <span class="block__nav-title" :class="{
                  bold: (
                    $store.state.route.name == 'pages' &&
                    (
                      $store.state.route.path == '/pages/qui-sommes-nous' ||
                      $store.state.route.path == '/pages/histoire-de-la-schl' ||
                      $store.state.route.path == '/pages/organisation' ||
                      $store.state.route.path == '/pages/accueil' ||
                      $store.state.route.path == '/pages/cadeau' ||
                      $store.state.route.path == '/pages/programme' ||
                      $store.state.route.path == '/pages/festival' ||
                      $store.state.route.path == '/pages/inscription' ||
                      $store.state.route.path == '/pages/prestations'
                    )
                  )
                }" title="Coopérative">Coopérative</span>
                <ul>
                  <li @click="showMenu()">
                    <router-link to="/pages/qui-sommes-nous" @click="dropDownShownPage = !dropDownShownPage;">
                      <span class="block__nav-title" title="Qui sommes nous ?"
                        :class="{bold: $store.state.route.path == '/pages/qui-sommes-nous'}">Qui sommes nous ?</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/histoire-de-la-schl" @click="dropDownShownPage = !dropDownShownPage;">
                      <span class="block__nav-title" title="Histoire de la SCHL"
                        :class="{bold: $store.state.route.path == '/pages/histoire-de-la-schl'}">Histoire de la
                        SCHL</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/accueil" @click="dropDownShownPage = !dropDownShownPage;">
                      <span class="block__nav-title" title="100ème"
                        :class="{bold: $store.state.route.path == '/pages/accueil'}">100ème</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/organisation" @click="dropDownShownPage = !dropDownShownPage;">
                      <span class="block__nav-title" title="Organisation"
                        :class="{bold: $store.state.route.path == '/pages/organisation'}">Organisation</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/prestations" @click="dropDownShownPage = !dropDownShownPage;">
                      <span class="block__nav-title" title="Prestations"
                        :class="{bold: $store.state.route.path == '/pages/prestations'}">Prestations</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/documents/statuts" @click="dropDownShownPage = !dropDownShownPage;">
                      <span class="block__nav-title" title="Statuts">Statuts</span>
                    </router-link>
                  </li>
                  <!-- <li @click="showMenu()" @mouseover="dropDownShown100 = true" @mouseleave="dropDownShown100 = false">
                    <a>
                      <span class="block__nav-title" :class="{
                                          bold: (
                                            $store.state.route.path == '/pages/accueil' ||
                                            $store.state.route.path == '/pages/cadeau' ||
                                            $store.state.route.path == '/pages/programme' ||
                                            $store.state.route.path == '/pages/festival' ||
                                            $store.state.route.path == '/pages/inscription'
                                          )
                                        }" title="100ème">100ème</span>
                    </a>
                  
                    <ul v-if="!dropDownShownPage || dropDownShown100" class="position-relative second-menu"
                      :class="!dropDownShown100 ? '' : '' ">
                      <li @click="showMenu()">
                        <router-link to="/pages/accueil">
                          <span class="block__nav-title" title="Accueil">Accueil</span>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/pages/accueil" v-scroll-to="'#h_54636241610701574689849297'">
                          <span class="block__nav-title" title="Le film">Le Film 100<sup>e</sup></span>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/pages/accueil" v-scroll-to="'#h_88098567111601574689861902'">
                          <span class="block__nav-title" title="Living Zoom">Living Zoom</span>
                        </router-link>
                      </li>
                  
                      <li>
                        <a href="https://www.facebook.com/SCHL100/" target="_blank">
                          <img src="@/assets/img/fb.svg" width="13" height="13">
                          &nbsp;<span class="block__nav-title" title=" SCHL100">SCHL100</span>
                        </a>
                      </li>
                    </ul>
                  </li> -->
                </ul>
              </li>
              <li class="dropdown" :class="{active: dropDownShownSocietariat}"
                @mouseover="activeDropDownSocietariat();" @mouseleave="dropDownShownSocietariat = false;">
                <span class="block__nav-title" :class="{bold: $store.state.route.params.id == 'societariat'}"
                  title="Sociétariat">Sociétariat</span>
                <ul>
                  <li @click="showMenu()">
                    <router-link to="/pages/societariat" v-scroll-to="'#page-wrap'"
                      @click="dropDownShownSocietariat = !dropDownShownSocietariat;">
                      <span class="block__nav-title" title="Etre Sociétaire">Etre Sociétaire </span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/societariat" v-scroll-to="'#devenir-societaires'"
                      @click="dropDownShownSocietariat = !dropDownShownSocietariat;">
                      <span class="block__nav-title" title="Conditions">Conditions</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/documents/statuts" @click="dropDownShownSocietariat = !dropDownShownSocietariat;">
                      <span class="block__nav-title" title="Statuts">Statuts</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="dropdown" :class="{active: dropDownShownProjects}" @mouseover="activeDropDownProjects();"
                @mouseleave="dropDownShownProjects = false">
                <span class="block__nav-title"
                  :class="{bold: ($store.state.route.name == 'projects' || $store.state.route.name == 'project' || $store.state.route.name == 'projects-redirect')}"
                  title="Projets">Projets</span>
                <ul>
                  <li @click="showMenu()">
                    <router-link to="/webcams">
                      <span class="block__nav-title" title="Webcams">Webcams</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/projects-redirect/etude">
                      <span class="block__nav-title" title="à l'étude">à l'étude</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/projects-redirect/en-cours">
                      <span class="block__nav-title" title="en cours">en cours</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/projects-redirect/termine">
                      <span class="block__nav-title" title="achevés">achevés</span>
                    </router-link>
                  </li>
                </ul>

              </li>
              <li class="dropdown" :class="{active: dropDownShownLocataire}" @mouseover="activeDropDownLocataire();"
                @mouseleave="dropDownShownLocataire = false">
                <span class="block__nav-title" :class="{bold: $store.state.route.params.id == 'devenir-locataire'}"
                  title="Devenir locataire">Devenir locataire </span>
                <ul>
                  <li @click="showMenu()">
                    <router-link to="/pages/devenir-locataire" v-scroll-to="'#page-wrap'">
                      <span class="block__nav-title" title="IMMEUBLES DE LA SCHL">IMMEUBLES DE LA SCHL</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/devenir-locataire" v-scroll-to="'#immeubles-geres-par-schl'">
                      <span class="block__nav-title" title="IMMEUBLES GÉRÉS PAR NOS SOINS">IMMEUBLES GÉRÉS PAR NOS SOINS
                      </span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/objets-a-louer">
                      <span class="block__nav-title" title="Objets à louer">Objets à louer</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/documents/loyers">
                      <span class="block__nav-title" title="Loyers des immeubles">Loyers des immeubles</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="dropdown" :class="{active: dropDownShownBuildings}" @mouseover="activeDropDownBuildings();"
                @mouseleave="dropDownShownBuildings = false">
                <span class="block__nav-title"
                  :class="{bold: ($store.state.route.name == 'buildings' || $store.state.route.name == 'buildings-tiers' || $store.state.route.name == 'building')}"
                  title="Immeubles">Immeubles</span>
                <ul>
                  <li @click="showMenu()">
                    <router-link to="/immeubles">
                      <span class="block__nav-title" title="SCHL">SCHL</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/immeubles-tiers">
                      <span class="block__nav-title" title="Gérés pour des tiers">Gérés pour des tiers </span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="dropdown" :class="{active: dropDownShownDocuments}" @mouseover="activeDropDownDocuments();"
                @mouseleave="dropDownShownDocuments = false">
                <span class="block__nav-title"
                  :class="{bold: ($store.state.route.name == 'librairies' || $store.state.route.name == 'news')}"
                  title="Documents">Documents</span>
                <ul>
                  <li @click="showMenu()">
                    <router-link to="/news">
                      <span class="block__nav-title" title="Actualités">Actualités</span>

                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/documents/statuts" @click="dropDownShownDocuments = !dropDownShownDocuments;">
                      <span class="block__nav-title" title="Statuts">Statuts</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/documents/publications"
                      @click="dropDownShownDocuments = !dropDownShownDocuments;">
                      <span class="block__nav-title" title="Publications">Publications</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/documents/presse" @click="dropDownShownDocuments = !dropDownShownDocuments;">
                      <span class="block__nav-title" title="Presse">Presse</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/documents/divers">
                      <span class="block__nav-title" title="Divers">Divers</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/archives">
                      <span class="block__nav-title" title="Archives">Archives</span>
                    </router-link>
                  </li>

                </ul>
              </li>
              <li class="dropdown" :class="{active: dropDownShownContact}" @mouseover="activeDropDownContact();"
                @mouseleave="dropDownShownContact = false">
                <span class="block__nav-title"
                  :class="{bold: ($store.state.route.name == 'contact' || $store.state.route.name == 'faqs' || $store.state.route.path == '/pages/liens')}"
                  title="Contact">Contact</span>
                <ul>
                  <li @click="showMenu()">
                    <router-link to="/pages/contact" v-scroll-to="'#page-wrap'">
                      <span class="block__nav-title" title="Contact">Contact</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/contact" v-scroll-to="'#formulaire'"
                      @click="dropDownShownContact = !dropDownShownContact;">
                      <span class="block__nav-title" title="Formulaire de contact">Formulaire de contact</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/coordonnees-bancaires">
                      <span class="block__nav-title" title="Coordonnées bancaires">Coordonnées bancaires</span>
                    </router-link>
                  </li>
                  <li @click="showMenu()">
                    <router-link to="/pages/liens">
                      <span class="block__nav-title" title="Liens">Liens</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="block__menu-button d-flex d-lg-none" @click="showMenu()" :class="{active: menuShown}">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="utils--spacer-default"></div>
      <div class="header__badge-container">
        <div class="header__badge">
          <a href="https://www.youtube.com/watch?v=r_IhHfuC9Ss&feature=youtu.be" target="_blank">
            <h4 class="mb-0 text-center">
              📽<br>
              <strong>Voir le film<br>du 100<sup>e</sup></strong>
            </h4>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data: function(){
    return {
      menuShown: 0,
      dropDownShownPage: 0,
      dropDownShownBuildings: 0,
      dropDownShownDocuments: 0,
      dropDownShownSocietariat: 0,
      dropDownShownLocataire: 0,
      dropDownShownProjects: 0,
      dropDownShownContact: 0,
      dropDownShown100: 0,
      pageScrolled: 0,
      isMobile: 0
    }
  },
  created: function(){
  },
  mounted: function(){
    this.isMobile = window.innerWidth < 992;
    window.addEventListener('resize', function(){this.isMobile = window.innerWidth < 992; console.log(this.isMobile) });
  },
  methods: {
    showMenu: function(){
      this.menuShown = !this.menuShown
    },
    isScrolled: function(){
      var wrap = document.getElementById('page-wrap');
      this.pageScrolled = (window.pageYOffset > 0 && (window.outerHeight < wrap.offsetHeight - 200))
    },
    logEvent: function(event){
      console.log(event)
    },
    delay: (ms) => {
      console.log('delay');
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    activeDropDownPage: async function(){
      console.log('activeDropDownPage');
      if(this.isMobile){
        await this.delay(100)
        this.dropDownShownPage = true
      }
      else{
        this.dropDownShownPage = true;
      }
    },
    activeDropDownBuildings: async function(){
      console.log('activeDropDownBuildings');
      if(this.isMobile){
        await this.delay(100)
        this.dropDownShownBuildings = true
      }
      else{
        this.dropDownShownBuildings = true;
      }
    },
    activeDropDownDocuments: async function(){
      console.log('activeDropDownDocuments');
      if(this.isMobile){
        await this.delay(100)
        this.dropDownShownDocuments = true
      }
      else{
        this.dropDownShownDocuments = true;
      }
    },
    activeDropDownSocietariat: async function(){
      console.log('activeDropDownSocietariat');
      if(this.isMobile){
        await this.delay(100)
        this.dropDownShownSocietariat = true
      }
      else{
        this.dropDownShownSocietariat = true;
      }
    },
    activeDropDownLocataire: async function(){
      console.log('activeDropDownLocataire');
      if(this.isMobile){
        await this.delay(100)
        this.dropDownShownLocataire = true
      }
      else{
        this.dropDownShownLocataire = true;
      }
    },
    activeDropDownProjects: async function(){
      console.log('activeDropDownProjects');
      if(this.isMobile){
        await this.delay(100)
        this.dropDownShownProjects = true
      }
      else{
        this.dropDownShownProjects = true;
      }
    },
    activeDropDownContact: async function(){
      console.log('activeDropDownContact');
      if(this.isMobile){
        await this.delay(100)
        this.dropDownShownContact = true
      }
      else{
        this.dropDownShownContact = true;
      }
    },
  }
}
</script>
