import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/pages/Home.vue')
    },
    { path: '/immeuble', redirect: '/immeubles' },
    { path: '/immeubles/tiers', redirect: '/immeubles-tiers' },
    { path: '/immeuble/:slug', redirect: '/immeubles/:slug' },
    {
      path: '/projets',
      name: 'projects',
      component: () => import(/* webpackChunkName: "projets" */ './views/projects/Index.vue')
    },
    {
      path: '/projets/:id',
      name: 'project',
      component: () => import(/* webpackChunkName: "projet" */ './views/projects/View.vue')
    },
    {
      path: '/projects-redirect/:status',
      name: 'projects-redirect',
      component: () => import(/* webpackChunkName: "projects-redirect" */ './views/projects/Redirect.vue')
    },
    {
      path: '/immeubles',
      name: 'buildings',
      component: () => import(/* webpackChunkName: "buildings" */ './views/buildings/Index.vue')
    },
    {
      path: '/immeubles-tiers',
      name: 'buildings-tiers',
      component: () => import(/* webpackChunkName: "buildings-tier" */ './views/buildings/IndexTiers.vue')
    },
    {
      path: '/carte',
      name: 'map',
      component: () => import(/* webpackChunkName: "map" */ './views/buildings/Map.vue')
    },
    {
      path: '/carte/:id',
      name: 'map-view',
      component: () => import(/* webpackChunkName: "map-view" */ './views/buildings/MapView.vue')
    },
    {
      path: '/immeubles/:id',
      name: 'building',
      component: () => import(/* webpackChunkName: "building" */ './views/buildings/View.vue')
    },
    {
      path: '/pages/:id',
      name: 'pages',
      component: () => import(/* webpackChunkName: "pages" */ './views/pages/View.vue')
    },
    { path: '/100', redirect: '/pages/accueil' },
    
    {
      // /search/screens -> /search?q=screens
      path: '/documents',
      name: 'librairies',
      component: () => import(/* webpackChunkName: "librairies" */ './views/librairies/Publications.vue')
    },
    {
      path: '/documents/:type',
      name: 'librairies-type',
      component: () => import(/* webpackChunkName: "librairies-type" */ './views/librairies/Publications.vue')
    },
    {
      path: '/publications/:type',
      redirect: to => {
        return { path: '/documents/'+to.params.type }
      },
    },
    {
      path: '/publications',
      redirect: '/documents'
    },
    {
      path: '/archives',
      name: 'archives',
      component: () => import(/* webpackChunkName: "archives" */ './views/librairies/Archives.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import(/* webpackChunkName: "news" */ './views/news/Index.vue')
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: () => import(/* webpackChunkName: "faqs" */ './views/faqs/Index.vue')
    },
    {
      path: '/objets-a-louer',
      name: 'availables',
      component: () => import(/* webpackChunkName: "availables" */ './views/availables/Index.vue')
    },
    {
      path: '/webcams',
      name: 'webcams',
      component: () => import(/* webpackChunkName: "webcams" */ './views/webcams/Index.vue')
    },
    {
      path: '/carousels/:id',
      name: 'carousel-view',
      component: () => import(/* webpackChunkName: "carousel" */ './views/carousels/View.vue')
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
