import Vue from 'vue'
import Vuex from 'vuex'

import buildings from '@/store/modules/buildings';
import projects from '@/store/modules/projects';
import sliders from '@/store/modules/sliders';
import pages from '@/store/modules/pages';
import librairies from '@/store/modules/librairies';
import posts from '@/store/modules/posts';
import faqs from '@/store/modules/faqs';
import availables from '@/store/modules/availables';
import webcams from '@/store/modules/webcams';
import flash from '@/store/modules/flash';
import gifts from '@/store/modules/gifts';
import carousels from '@/store/modules/carousels';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    buildings,
    projects,
    sliders,
    pages,
    librairies,
    posts,
    faqs,
    availables,
    webcams,
    flash,
    gifts,
    carousels
  }
})
