import createCrudModule from 'vuex-crud';
import {client, parseResponse} from '@/api'

export default createCrudModule({
  resource: 'gifts',
  client,
  parseSingle: parseResponse,
  parseList: parseResponse,
  customUrlFn() {
    return process.env.VUE_APP_API+'api/gifts/list';
  }
});
