import createCrudModule from 'vuex-crud';
import {client, parseResponse} from '@/api'

export default createCrudModule({
  resource: 'pages',
  idAttribute: 'slug',
  client,
  parseSingle: parseResponse,
  parseList: parseResponse,
  customUrlFn(slug) {
    return slug ? process.env.VUE_APP_API+`api/pages/view/${slug}` :  process.env.VUE_APP_API+'api/pages';
  }
});
