import createCrudModule from 'vuex-crud';
import {client, parseResponse} from '@/api'

export default createCrudModule({
  resource: 'buildings',
  idAttribute: 'slug',
  client,
  parseSingle: parseResponse,
  parseList: parseResponse,
  customUrlFn(slug) {
    return slug ? process.env.VUE_APP_API+`api/buildings/view/${slug}` :  process.env.VUE_APP_API+'api/buildings';
  }
});
